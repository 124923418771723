@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Neuton&family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Neuton&family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Neuton&family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One&family=Amatic+SC:wght@700&family=Cabin+Sketch&family=Fredericka+the+Great&family=Great+Vibes&family=Holtwood+One+SC&family=Lavishly+Yours&family=Leckerli+One&family=Merienda:wght@700&family=Monoton&family=Ms+Madi&family=Permanent+Marker&family=Rock+Salt&family=Rubik+Glitch&family=Rubik+Moonrocks&family=Rubik+Wet+Paint&family=Sacramento&family=Shadows+Into+Light&family=Smooch&family=Titan+One&family=Water+Brush&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  /*left: 0;
  top: 0;*/
  height: 25%;
  width: 25%;
  position: absolute;
}

.container h2 {
  text-align: center;
  margin: 25px auto;
}
.smaller {
  -webkit-text-size-adjust: 50%;
     -moz-text-size-adjust: 50%;
          text-size-adjust: 50%;
}

.hr{
  color:antiquewhite;
  margin: 1rem;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.navbar {
  font: 400 1em/1 "Montserrat";
  z-index: 5000;
  /*margin-bottom: 5em !important;*/
}

body {
  font: 400 1em/1 "Montserrat";
  background: #090d00;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
header{
  margin-bottom: 0em;
}

.list-group{
  margin-bottom: -2.5em !important;
}

.list-group-item.active, .form-control::selection {
background-color: #000;
}

.list-group-item, .form-control, .navbar-purple {
  /*color: #6610f2;*/
  /*background-color: #6610f2;*/
  border: #6610f2;
  border-width: 20px;
 
}

.crown{
  height: 4rem;
}

.formFix{
  background-color: #6610f2;
  border-radius: 0.25rem;
}

.MuiPaper-root{
  background-color: #090d00 !important;
  margin-bottom: 2em;
  color: #FFF !important;
}

.MuiButton-containedSecondary {
  color: #fff;
   /*NOTE: You can include all these using the shorthand 'animation' property, but some older  browsers require that all the values be in their own property. Additionally, I feel its easier to learn/demonstrate this way*/
   -webkit-animation-name: color-transition;
   animation-name: color-transition;
   -webkit-animation-duration: 25s;
   animation-duration: 25s;
   -webkit-animation-direction: alternate;
   animation-direction: alternate;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   /*default value for timing-function is ease, we'll specify linear to keep the same timing through out*/
   -webkit-animation-timing-function: linear;
   animation-timing-function: linear
}

a, a:hover {
  background-color: none;
  color:azure;
}

.btn, .btn:hover {
  background-color: #6610f2;
  color:azure;
}

.bg-color-change {

  /*NOTE: You can include all these using the shorthand 'animation' property, but some older  browsers require that all the values be in their own property. Additionally, I feel its easier to learn/demonstrate this way*/
  -webkit-animation-name: color-transition;
  animation-name: color-transition;
  -webkit-animation-duration: 25s;
  animation-duration: 25s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*default value for timing-function is ease, we'll specify linear to keep the same timing through out*/
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;

}

.form{
  font: 700 1.5rem/1 "Oswald", sans-serif;
  color: white;
  -webkit-text-decoration: #f50057 !important;
          text-decoration: #f50057 !important;
  stroke: #f50057 !important;
  letter-spacing: 0;
  display: block;
  white-space: wrap !important;
}

.logo-smaller{
  width: 10em;
  margin-bottom: -2em;
  margin-top: -2em;
}

.MuiFormLabel-root {
  color: white !important;
}

.MuiInputBase-input {
  color: white !important;
}

.fixLogo{

  width: 16em !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: -4.5rem;
  margin-bottom: -0.75rem;
  
}

.logo-box {
  margin-left: auto;
  margin-right: auto;
  margin-top: -3.25rem;
  margin-bottom: -0.75rem;
  max-width: 40vw;
}

.logo-box-top-buy {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -0.75rem;
  max-width: 40vw;
}

.logo-box-top-add {
  margin-left: auto;
  margin-right: auto;
  margin-top: -2rem;
  margin-bottom: -0.75rem;
  max-width: 40vw;
}

.material-symbols-outlined{
  padding-top: 0.2rem !important;
}

.symbol {
  max-width: 1rem;
  padding-bottom: 0.25rem;
}

.symbolSmaller {
  max-width: 0.75rem;
  padding-bottom: 0.25rem;
}

.symbolBuyList {
  color: #ffc439 !important;
}

.symbol2 {
  max-width: 1.5rem;
  margin-right: 1.5rem;
}

.symbolBadge {
  max-width: 0.5rem;
  padding-bottom: 0.25rem;
}

.symbolGif {
  max-width: 5rem;
  mix-blend-mode: difference;
}

.symbolBigger {
  width: 0.5em;
  margin-top: -3rem !important;
  margin-right: 0.5rem !important;
}
.symbolBigger::after {
  padding-left: 3rem !important;
}
.symbolBiggerText {
  font-size: 5em;
}

#symbol {
  margin-right: 4rem;
}

.nowrap {
  white-space: pre; 
}

.spacer-bottom {
  margin-bottom: 1.5em;
}

.btn-group {
  margin-right: 1em;
  margin-bottom: 1em;
}

.bigButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

}

.noKern {
  -webkit-font-feature-settings: "kern" off;
          font-feature-settings: "kern" off;
  font-kerning: none;
  letter-spacing: -2px;
}

.avatar {
  border-radius: 50px;
  height: 2rem;
  margin-right: 0.5rem;
  /*width: 30;
  height: 44;*/
}

.avatar-lg {
  border-radius: 50px;
  height: 6rem;
  margin-right: 0.5rem;
}

.signedIn {
  margin-left: -3.5rem;
}

.hidden {
  display: none;
  height: 0;
}

/*This is the animation used to change colors; In this instance, I made sure to have the same color at 0% as I did at 100% */
  
@-webkit-keyframes color-transition {
  0% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
  33% {
    background-color: #80D39B;
    border-color: #80D39B;
  }
  66% {
    background-color: #BE3E82;
    border-color: #BE3E82;
  }
  100% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
}

@keyframes color-transition {
  0% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
  33% {
    background-color: #80D39B;
    border-color: #80D39B;
  }
  66% {
    background-color: #BE3E82;
    border-color: #BE3E82;
  }
  100% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
}


@media screen and (min-width: 1900px) {
  .logo {
    width: 12rem;
  }
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.nav-link2{
  /*padding-left: -5em;*/
  /*padding-right: -2em;*/
  margin-left: 1rem;
}
/* Fonts to Pick From Randomly */
.f0 {font: 700 1rem 'Leckerli One', cursive;}
.f1 {font: 700 1rem 'Alumni Sans Inline One', cursive;}
.f2 {font: 700 1rem 'Amatic SC', cursive;}
.f3 {font: 700 1rem 'Cabin Sketch', cursive;}
.f4 {font: 700 1rem 'Fredericka the Great', cursive;}
.f5 {font: 700 1rem 'Great Vibes', cursive;}
.f6 {font: 700 1rem 'Holtwood One SC', serif;}
.f7 {font: 700 1rem 'Lavishly Yours', cursive;}
.f8 {font: 700 1rem 'Merienda', cursive;}
.f9 {font: 700 1rem 'Monoton', cursive;}
.f10 {font: 700 1rem 'Ms Madi', cursive;}
.f11 {font: 700 1rem 'Permanent Marker', cursive;}
.f12 {font: 700 1rem 'Rock Salt', cursive;}
.f13 {font: 700 1rem 'Rubik Glitch', cursive;}
.f14 {font: 700 1rem 'Rubik Moonrocks', cursive;}
.f15 {font: 700 1rem 'Rubik Wet Paint', cursive;}
.f16 {font: 700 1rem 'Sacramento', cursive;}
.f17 {font: 700 1rem 'Shadows Into Light', cursive;}
.f18 {font: 700 1rem 'Smooch', cursive;}
.f19 {font: 700 1rem 'Titan One', cursive;}
.f20 {font: 700 1rem 'Water Brush', cursive;}


.stageFontFixer{
  margin-top: -4.125em;
  z-index: 70000;
  padding-top: 31em;
  padding-bottom: 0em;
}

.pushUp{
  margin-top: -46.5em;
  margin-bottom: 5em;
}

.pushLeft{
  /*margin-left: 3.5em;*/
  margin-right: auto;
}

.pushRight{
  /*margin-left: -3.5em;*/
  margin-right: auto;
  margin-top: -78em;
}

.video {
  object-fit: cover;
  width: 100vw;
  height: 80vh;
  position: fixed;
  top: 0;
  left: 0;
}


h1{
  font-size: 4rem;
}


/* Make the youtube video responsive */
.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.liveVideo{
  border: 0 !important;
  z-index: 100;
  /*position:absolute;*/
  /*left:0;*/
  width:100% !important;
  height: 20em !important;
  max-height:75%;
  /*border-radius: 13px;*/
  /*padding: 0.125em;*/
  /*padding-left: 0.085em;*/
  /*padding-right: 0.005em;*/
  /*margin-bottom: 10em;*/
}

.liveVideo-after{
  margin-bottom: 3rem !important;
}

.monitorVideo{
  position:absolute;
  top:10em;
  left:0;
  width:60em;
  height:40em;
  padding: 0.125em;
  margin-bottom: 10em;
  z-index: 0;
}

.headerColorFix {
  border-radius: 8px;
  border: none;
  padding: 8px 12px;
  color: whitesmoke;
  background-color: purple;
  font-weight: 800;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  background-position: -120px -120px, 0 0;
  background: linear-gradient(45deg, yellow, purple, red, blue);
  background-size: 250% 250%, 100% 100%;
}

.glow-on-hover {
    background: #111;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing 20s linear infinite;
            animation: glowing 20s linear infinite;
    opacity: 01;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@-webkit-keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.headerSpacer{
  padding: 0.75em !important;
}

.headerSpacer2{
  padding: 2em !important;
  font-size: 1.2em !important
}

.award{
  max-width: 4rem;
  margin-top: -1rem;
  margin-bottom: -3rem;
  margin-right: 0.5rem;
  margin-left: -14rem;
}

.artist {
  font-size: 0.7em !important;
  padding-bottom: -5em;
  z-index: 5000;
}

.balance {
  font-size: 0.7em !important;
  margin-top: -1.5em;
}


.song {
  font-size: 0.5em !important;
  padding: 0.1em;
  margin-right: 0.5em;
}


.touchButton {
  font-size: 1.5em !important;
  margin: 0.3rem -0.7rem -0.7rem -0.7rem;
  right: 2rem;
}

.live-time{
  font-size: 1rem !important;
  margin-top: -28rem;
}

.reverse{
    margin-left: -1.5rem !important;
    margin-right: -3rem !important;
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
    -o-transform:rotateY(180deg);
    -ms-transform:rotateY(180deg);
    font-size: 3rem !important;
    margin-top: -0.5rem;
}



.performing-now {
  /*text-align: justify;*/
  letter-spacing: 0;
  display: block;
  
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
  /* Clip Background Image */
  -webkit-background-clip: text;
  background-clip: text;
  /* Animate Background Image */
  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 80s linear infinite;
          animation: aitf 80s linear infinite;
  /* Activate hardware acceleration for smoother animations */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* border: rgb(248, 17, 17) 0.125em double; */

  -webkit-animation-name: color-transition;
  animation-name: color-transition;
  -webkit-animation-duration: 25s;
  animation-duration: 25s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.prompter{
  height: 93vh;
  width: 100vw;
}

.song-onstage {
  /*font: 700 12vw "Neuton", sans-serif;*/
  line-height: 50% !important;
  /*letter-spacing: .12em;  just enough to clear the last letter;
                            even .2em  doesn't look bad; */
  /*z-index: 500;*/
  padding-right: 6em;
}
.z10 {
  z-index: 10;
}

.performer-onstage {
  font: 700 6vw "Oswald", sans-serif;
}

.coins-onstage {
  font: 700 3em "Oswald", sans-serif;
}

.performing-now:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
  height: 0;
}

.pre {
  white-space: pre !important;
}

.coinWalletPadding{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 2rem;
  padding-left: 0.25rem;
}

.notEnoughCoins{
  opacity: 0.25;
  top: -4em !important;
}

.activeBG {
  margin-left: -1em !important;
  margin-right: -1em !important;
  border-radius: 2rem !important;
  border-block-width: 0.25rem !important;
  letter-spacing: 0;
  display: block;
  /* Clip Background Image */
  /*-webkit-background-clip: text;
  background-clip:border-box;
  /* Animate Background Image */
  /* -webkit-text-fill-color: transparent; */
  -webkit-animation: aitf 80s linear infinite;
          animation: aitf 80s linear infinite;
  /* Activate hardware acceleration for smoother animations */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-animation-name: color-transition;
  animation-name: color-transition;
  -webkit-animation-duration: 25s;
  animation-duration: 25s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.inactiveBG {
  background-color:rgba(255, 255, 255, 0);
}

.list-group-item.disabled, .list-group-item:disabled {
  background-color:rgba(255, 255, 255, 0) !important ;
}

.makeIconBigger{
  font-size: 3em !important;
}

.makeLogoSmaller{
  max-width: 3rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.listNumber{
  margin: auto;
}

.rewardKoinLabel{
  height: 1rem;
  margin-top: -1.25rem;
}

.storeKoin{
  height: 1rem;
  margin-top: -2rem;
  margin-right: 0.25rem;
  color: #000 !important;
}

.storeKoin2{
  height: 0.75rem;
  margin-top: -.75rem;
  margin-right: 0.1rem;
  color: #000 !important;
}

.storeKoin3{
  height: 1rem;
  margin-top: -2rem;
  margin-right: 0.25rem;
  color: #6610f2 !important;
}

.rewardKoinLabel, h3{
  height: 1rem;
  margin-bottom: -0.125rem !important;
}

.rewardKoinButtoSpacer{
  margin-top: 0.35em;
  margin-bottom: 0.35em;
}

.rewardKoinButton{
  height: 1rem;
  margin-top: -1rem;
  margin-right: -0.75rem;
  margin-left: 0.5rem;
}

.rewardKoinPic{
  height: 2rem;
  margin-top: 0.5rem;
}

.list-group-item, .navbar-purple {
  background-color: transparent !important;
  border-color: transparent !important;
  color: azure;
}

.waitingList{
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border: 0;
  border-radius: 0.5rem;
  color: #6610f2;
  height: 6.5rem;
  max-width: 85vw;
  padding: 0.01rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  -webkit-font-feature-settings:"smcp";
          font-feature-settings:"smcp";
  font-variant-caps:small-caps;

}

.nonVIPList{
  background:#6610f2;
  border: 0;
  border-radius: 0.5rem;
  color: #FFF;
  /*height: 5rem;*/
  max-width: 40em;
  /*padding: 0.01rem;*/
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

}

.animatedLines {
  border-radius: 0.5rem;
  border-width: 0.2rem;
  letter-spacing: 0;
  display: block;
  /* Clip Background Image */
  /*-webkit-background-clip: text;
  background-clip:border-box;
  /* Animate Background Image */
  /* -webkit-text-fill-color: transparent; */
  -webkit-animation: aitf 80s linear infinite;
          animation: aitf 80s linear infinite;
  /* Activate hardware acceleration for smoother animations */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-animation-name: color-transition;
  animation-name: color-transition;
  -webkit-animation-duration: 25s;
  animation-duration: 25s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}


h4 {
  font: 700 1.5rem/1 "Oswald", sans-serif;
  background-color: #6610f2;
  color: white;
  letter-spacing: 0;
  display: block;
  white-space: wrap !important;
  
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
  /* Clip Background Image */
  -webkit-background-clip: text;
  background-clip: text;
  /* Animate Background Image */
  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 80s linear infinite;
          animation: aitf 80s linear infinite;
  /* Activate hardware acceleration for smoother animations */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* border: rgb(248, 17, 17) 0.125em double; */
  padding: 0.25em;
}

.alarm {
  font: 700 1rem/1 "Oswald", sans-serif;
  background-color: #ef5350;
  
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
  /* Clip Background Image */
  -webkit-background-clip: text;
  background-clip: text;
  /* Animate Background Image */
  -webkit-text-fill-color: transparent;
  /* Activate hardware acceleration for smoother animations */
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;

}

.stickyFooter{
  position: fixed;
  bottom: 0 !important;
}

.shopFont {
  font: 700 1.5rem/1 "Oswald", sans-serif !important;
  letter-spacing: 0;
}

.shopFontBigger {
  font: 700 2.5rem/1 "Oswald", sans-serif !important;
  letter-spacing: 0;
}

.shopFontTM {
  font: 700 0.5rem/1 "Oswald", sans-serif !important;
  margin-bottom: 5rem;
  letter-spacing: 0;
}

.shopFont2 {
  font: 700 1.5rem/1 "Montserrat", sans-serif !important;
  font-size: 2rem !important;
  letter-spacing: 0;
}

.shopFont2Smaller {
  font: 700 1rem/1 "Montserrat", sans-serif !important;
  font-size: 2.25rem !important;
  letter-spacing: 0;
}

  /*This is the animation used to change colors; In this instance, I made sure to have the same color at 0% as I did at 100% */
  
  @-webkit-keyframes color-transition {
    0% {
      background-color: #4C6085;
      border-color: #4C6085;
    }
    33% {
      background-color: #80D39B;
      border-color: #80D39B;
    }
    66% {
      background-color: #BE3E82;
      border-color: #BE3E82;
    }
    100% {
      background-color: #4C6085;
      border-color: #4C6085;
    }
  }
  
  @keyframes color-transition {
    0% {
      background-color: #4C6085;
      border-color: #4C6085;
    }
    33% {
      background-color: #80D39B;
      border-color: #80D39B;
    }
    66% {
      background-color: #BE3E82;
      border-color: #BE3E82;
    }
    100% {
      background-color: #4C6085;
      border-color: #4C6085;
    }
  }


@media handheld, only screen and (min-width: 767px) {
    .submit-form {
    line-height: 15px;
    background-color: transparent;
    height: 500px;
    width:600px;
    max-width: 600px; 
    float: left;
    padding: 10px;
    font-size: 100%;
    margin: 10px;
    word-wrap: break-word;
    }
}

@media handheld, only screen and (max-width: 767px) {
    .submit-form {
    line-height: 15px;
    background-color: transparent;
    height: 500px;
    width:600px;
    max-width: 320px; /* or whatever size you want */
    float: left;
    padding: 10px;
    font-size: 100%;
    margin: 10px;
    word-wrap: break-word;
    }
}

/*@media screen and (min-width: 1400px) {
  .container {
    margin: 6em;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    margin: 6em;
  }
}
@media screen and (min-width: 1900px) {
  .container {
    width: 100vw;
  }
  .logo {
    width: 12rem;
  }
}*/
.form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 1 !important;
    color: black !important;
}

li {
    list-style-type: none;
}
