@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Neuton&family=Oswald:wght@700&display=swap');
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.nav-link2{
  /*padding-left: -5em;*/
  /*padding-right: -2em;*/
  margin-left: 1rem;
}