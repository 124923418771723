@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Neuton&family=Oswald:wght@700&display=swap');
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  /*left: 0;
  top: 0;*/
  height: 25%;
  width: 25%;
  position: absolute;
}

.container h2 {
  text-align: center;
  margin: 25px auto;
}
.smaller {
  text-size-adjust: 50%;
}

.hr{
  color:antiquewhite;
  margin: 1rem;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.navbar {
  font: 400 1em/1 "Montserrat";
  z-index: 5000;
  /*margin-bottom: 5em !important;*/
}

body {
  font: 400 1em/1 "Montserrat";
  background: #090d00;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
header{
  margin-bottom: 0em;
}

.list-group{
  margin-bottom: -2.5em !important;
}

.list-group-item.active, .form-control::selection {
background-color: #000;
}

.list-group-item, .form-control, .navbar-purple {
  /*color: #6610f2;*/
  /*background-color: #6610f2;*/
  border: #6610f2;
  border-width: 20px;
 
}

.crown{
  height: 4rem;
}

.formFix{
  background-color: #6610f2;
  border-radius: 0.25rem;
}

.MuiPaper-root{
  background-color: #090d00 !important;
  margin-bottom: 2em;
  color: #FFF !important;
}

.MuiButton-containedSecondary {
  color: #fff;
   /*NOTE: You can include all these using the shorthand 'animation' property, but some older  browsers require that all the values be in their own property. Additionally, I feel its easier to learn/demonstrate this way*/
   -webkit-animation-name: color-transition;
   animation-name: color-transition;
   -webkit-animation-duration: 25s;
   animation-duration: 25s;
   -webkit-animation-direction: alternate;
   animation-direction: alternate;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   /*default value for timing-function is ease, we'll specify linear to keep the same timing through out*/
   -webkit-animation-timing-function: linear;
   animation-timing-function: linear
}

a, a:hover {
  background-color: none;
  color:azure;
}

.btn, .btn:hover {
  background-color: #6610f2;
  color:azure;
}

.bg-color-change {

  /*NOTE: You can include all these using the shorthand 'animation' property, but some older  browsers require that all the values be in their own property. Additionally, I feel its easier to learn/demonstrate this way*/
  -webkit-animation-name: color-transition;
  animation-name: color-transition;
  -webkit-animation-duration: 25s;
  animation-duration: 25s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*default value for timing-function is ease, we'll specify linear to keep the same timing through out*/
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;

}

.form{
  font: 700 1.5rem/1 "Oswald", sans-serif;
  color: white;
  text-decoration: #f50057 !important;
  stroke: #f50057 !important;
  letter-spacing: 0;
  display: block;
  white-space: wrap !important;
}

.logo-smaller{
  width: 10em;
  margin-bottom: -2em;
  margin-top: -2em;
}

.MuiFormLabel-root {
  color: white !important;
}

.MuiInputBase-input {
  color: white !important;
}

.fixLogo{

  width: 16em !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: -4.5rem;
  margin-bottom: -0.75rem;
  
}

.logo-box {
  margin-left: auto;
  margin-right: auto;
  margin-top: -3.25rem;
  margin-bottom: -0.75rem;
  max-width: 40vw;
}

.logo-box-top-buy {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -0.75rem;
  max-width: 40vw;
}

.logo-box-top-add {
  margin-left: auto;
  margin-right: auto;
  margin-top: -2rem;
  margin-bottom: -0.75rem;
  max-width: 40vw;
}

.material-symbols-outlined{
  padding-top: 0.2rem !important;
}

.symbol {
  max-width: 1rem;
  padding-bottom: 0.25rem;
}

.symbolSmaller {
  max-width: 0.75rem;
  padding-bottom: 0.25rem;
}

.symbolBuyList {
  color: #ffc439 !important;
}

.symbol2 {
  max-width: 1.5rem;
  margin-right: 1.5rem;
}

.symbolBadge {
  max-width: 0.5rem;
  padding-bottom: 0.25rem;
}

.symbolGif {
  max-width: 5rem;
  mix-blend-mode: difference;
}

.symbolBigger {
  width: 0.5em;
  margin-top: -3rem !important;
  margin-right: 0.5rem !important;
}
.symbolBigger::after {
  padding-left: 3rem !important;
}
.symbolBiggerText {
  font-size: 5em;
}

#symbol {
  margin-right: 4rem;
}

.nowrap {
  white-space: pre; 
}

.spacer-bottom {
  margin-bottom: 1.5em;
}

.btn-group {
  margin-right: 1em;
  margin-bottom: 1em;
}

.bigButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

}

.noKern {
  font-kerning: none;
  letter-spacing: -2px;
}

.avatar {
  border-radius: 50px;
  height: 2rem;
  margin-right: 0.5rem;
  /*width: 30;
  height: 44;*/
}

.avatar-lg {
  border-radius: 50px;
  height: 6rem;
  margin-right: 0.5rem;
}

.signedIn {
  margin-left: -3.5rem;
}

.hidden {
  display: none;
  height: 0;
}

/*This is the animation used to change colors; In this instance, I made sure to have the same color at 0% as I did at 100% */
  
@-webkit-keyframes color-transition {
  0% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
  33% {
    background-color: #80D39B;
    border-color: #80D39B;
  }
  66% {
    background-color: #BE3E82;
    border-color: #BE3E82;
  }
  100% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
}

@keyframes color-transition {
  0% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
  33% {
    background-color: #80D39B;
    border-color: #80D39B;
  }
  66% {
    background-color: #BE3E82;
    border-color: #BE3E82;
  }
  100% {
    background-color: #4C6085;
    border-color: #4C6085;
  }
}


@media screen and (min-width: 1900px) {
  .logo {
    width: 12rem;
  }
}